import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import apiServices from "../../../api/apiServices";
import ShopCard from "../../../components/card/shopCard";
import TopicCard from "../../../components/card/topicCard";
import Loader from "../../../components/loader";
import MainTitle from "../../../components/mainTitle";

function ChapterPage(props) {
  let subjectId = props.match.params.id;
  let subject = props.match.params.sub;
  const profile = useSelector((state) => state.profileReducer.profile);

  const [loading, setLoading] = useState(true);
  const [chapterData, setChapterData] = useState("");
  const [chapterErr, setChapterErr] = useState(false);

  const history = useHistory();

  const GetChapters = () => {
    let Param = `${subjectId}?streamName=${profile.stream}&gradeId=${profile.gradeId}&profileId=${profile.profiles[0]}`;
    apiServices
      .GetChaptersUrl(Param)
      .then((res) => {
        setChapterData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setChapterErr(true);
      });
  };

  const onTopicClick = (topic, chapter) => {
    if (chapter.purchased || chapter.price === "") {
      let id = topic.topicId;
      let name = topic.topicName;
      history.push(`/dashboard/topic/${id}/${name}`);
    } else {
      setOpen(true);
    }
  };
  const [open, setOpen] = useState(false);

  const handleClose = (value) => {
    setOpen(false);
  };
  useEffect(() => {
    GetChapters();
  }, []);

  return (
    <div>
      <MainTitle title={subject} />
      {chapterData?.length > 0 ? (
        <div style={{ marginTop: 10 }}>
          {chapterData.map((chapter) =>
            chapter.packages.map((lesson, index) => (
              <div key={index} className="chapter_page_bg">
                <MainTitle
                  subTitle={`Lesson ${lesson.lessonNumber}: ${lesson.lessonName}`}
                />
                <div className="chapter_page_lesson">
                  {lesson.topicIds.map((topic) => (
                    <TopicCard
                      topic={topic}
                      key={topic.id}
                      chapterInfo={chapter}
                      onClick={() => onTopicClick(topic, chapter)}
                    />
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      ) : (
        <Loader />
      )}
      <ShopCard
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}

export default ChapterPage;