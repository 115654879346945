import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import profileReducer from "./profileInfoReducer";
import userInfo from "./userReducer";


const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userReducer","profileReducer"],
};


const rootReducer = combineReducers({
    userReducer: userInfo,
    profileReducer: profileReducer,
});

export default persistReducer(persistConfig, rootReducer);