import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import apiServices from "../../../api/apiServices";
import SubjectCard from "../../../components/card/subjectCard";
import { logout } from "../../../redux/actions";

function Subject(props) {
    let history = useHistory();
  const { userData } = props;
  const [loading, setLoading] = useState(true);
  const [subjectData, setSubjectData] = useState("");
  const GetSubjects = () => {
    let Params = userData.gradeId + "?streamName=" + userData.stream;
    apiServices
      .GetSubjectsUrl(Params)
      .then((res) => {
        setSubjectData(res.data.data);
        setLoading(false);
        
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubjectClick=(id,sub)=>{
    history.push(`/dashboard/chapter/${id}/${sub}`)
  }
  const dispatch = useDispatch();
  const GetDate = () => {
    // //console.log(user)
    let userId;
    let token = localStorage.getItem("token");
    if (token) {
      const payloadStart = token.indexOf(".") + 1;
      const payloadEnd = token.lastIndexOf(".");
      let payload = token.substring(payloadStart, payloadEnd);

      if (payload.length === 0) {
      } else {
        payload = atob(payload);
        const jsonPayload = JSON.parse(payload);
        //console.log(jsonPayload);
        userId = jsonPayload.id;
      }
      apiServices
        .GetUserDetails(userId, token)
        .then((res) => {})
        .catch((error) => {
          dispatch(logout());
          localStorage.removeItem("token");
          props.history.push("/auth/phone_authentication");
          setLoading(false);
        });
    } else {
      props.history.push("/auth/phone_authentication");
    }
  };
  useEffect(() => {
    GetSubjects();
    GetDate()
  }, []);
  return (
    <div>
      <div className="landing_page">
        {subjectData ? (
          subjectData.map((subject, index) => (
            <SubjectCard
              key={index}
              bg={subject.color}
              title={subject.subjectName}
              caption={subject.description}
              icon={subject.image_path}
              onClick={()=>onSubjectClick(subject._id,subject.subjectName)} 
            />
          ))
        ) : null}
      </div>
      {loading? <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>:null}
    </div>
  );
}

export default Subject;
