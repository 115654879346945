import "./App.css";
import PublicStudentRoute from "./container/publicStudent";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthRoute from "./container/authentication";
import ProtectedRoute from "./protectedRoute";
import { useState } from "react";

function App(props) {
  const [isAuth, setIsAuth] = useState(true)
  const token = localStorage.getItem("token");
  return (
    <Switch>
      <Route exact path="/">
        {token ? <Redirect to={"/dashboard"} /> : <Redirect to={"/auth"} />}
      </Route>
      <ProtectedRoute path="/dashboard" component={PublicStudentRoute} isAuth={isAuth}/>
      <ProtectedRoute path="/auth" component={AuthRoute} isAuth={isAuth}/>
    </Switch>
  );
}
export default App;
