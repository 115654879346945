import React, { useState, useEffect } from "react";
import MainTitle from "../../../components/mainTitle";
import { useDispatch, useSelector } from "react-redux";
import apiServices from "../../../api/apiServices";
import SophiaButton from "../../../components/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { clearProfile, logout } from "../../../redux/actions";

function ShopPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const serializedState = localStorage.getItem('persist:root');
  const token = localStorage.getItem('token');
  const profileReducer =JSON.parse(serializedState)
  //console.log(JSON.parse(profileReducer.profileReducer))

  // let params = props.match.params.details;
  const profiles = JSON.parse(profileReducer.profileReducer);
  const profile = profiles.profile;
  const user = useSelector((state) => state.userReducer.user);
  const [packages, setPackages] = useState("");
  const [addedPackage, setAddedPackage] = useState([]);
  const [selectedPack, setSelectedPack] = useState({});
  const [packageErr, setPackageErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [scholarship, setScholarship] = useState({
    applied: false,
    scholarship: false,
  });
  const [scholarshipVal, setScholarshipVal] = useState("");
  const [checkCoupon, setCheckCoupon] = useState("");
  const [checkCouponValue, setCheckCouponValue] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [error, setError] = useState({
    msg: "",
    scholars: false,
  });
  const GetPackage = () => {
    let Param = `${profile.gradeId}?streamName=${profile.stream}&&profileId=${profile.profiles[0]}`;
    apiServices
      .GetPackages(Param)
      .then((res) => {
        setPackages(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        ////console.log(error, "GetPackages");
        setLoading(false);
        setPackageErr(true);
      });
  };

  useEffect(() => {
    GetPackage();
  }, []);

  const onAdd = (pack, i) => {
    let price = totalPrice + parseInt(pack.price);
    let percentage = price * (checkCouponValue/100)
    setDiscountAmount(percentage)
    //console.log(price, totalPrice, "price");
    setTotalPrice(price);
    let newSelected = { ...selectedPack };
    newSelected[i] = pack.packageId;
    setSelectedPack(newSelected);
    setAddedPackage([...addedPackage, pack.packageId]);
  };

  const onRemove = (pack, i) => {
    let price = totalPrice - parseInt(pack.price);
    let percentage = price * (checkCouponValue/100)
    setDiscountAmount(percentage)
    setTotalPrice(price);
    let newSelected = { ...selectedPack };
    newSelected[i] = 0;
    setSelectedPack(newSelected);
    const index = addedPackage.indexOf(pack.packageId);
    if (index > -1) {
      addedPackage.splice(index, 1);
    }
  };
  // const scholarshipHandler = (val) => {
  //   setError({
  //     scholars: false,
  //   });
  //   setScholarshipVal(val.target.value);
  // };
  // const scholarshipSubmit = () => {
  //   if (scholarshipVal.length > 0) {
  //     if (scholarshipVal < 50) {
  //       setScholarship({ applied: true, scholarship: false });
  //     } else {
  //       setScholarship({ applied: true, scholarship: true });
  //     }
  //   } else {
  //     setError({
  //       scholars: true,
  //       msg: "Enter your percentage!",
  //     });
  //   }
  // };

  const checkCoupValidity = () => {
    //console.log(checkCoupon, "checkCoupon");
    apiServices
      .CheckCoupValidity(`couponCode=${checkCoupon}`)
      .then((res) => {
        //console.log(res.data.data.value, "couponCode");
        let value=parseInt(res.data.data.value)
        setCheckCouponValue(value)
        let percentage = totalPrice * (value/100)
        setDiscountAmount(percentage)
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const buyNow = () => {
    // //console.log(token, "storedData @ shop");
    setLoading(true);
    let grandTotal = discountAmount ===0? totalPrice : totalPrice - discountAmount;
    let total = grandTotal === 0? grandTotal.toString() :grandTotal;
    let body = {
      profile_id: profile.profiles[0],
      amount: total,
      packageId: addedPackage,
    };
    apiServices
      .GetCreateOrder(body, profile.id, token)
      .then((res) => {
        //console.log(res.data, "GetCreateOrder");
        if (res.data.data.url === "SUCCESS") {
          history.push("/");
        } else {
          window.open(res.data.data.url, "_self");
        }
        setLoading(false);
      })
      .catch((error) => {
        // dispatch(logout());
        // dispatch(clearProfile());
        // localStorage.removeItem("token");
        // history.push("/auth/phone_authentication");
        setLoading(false);
      });
  };

  return (
    <div>
      <MainTitle
        title={`Hello ${profile.name}`}
        subTitle="Conceptual Video classes, practices and qualitative notes."
        para="Hurry.. Exciting offers now!!"
        style={{ margin: "0px" }}
      />
      <div className="shop_page">
        <div className="shop_page_body">
          <div>
            {packages.length > 0 ? (
              packages.map((p, index) =>
                p.price ? (
                  <div
                    className="package_card"
                    style={{
                      backgroundColor:
                        selectedPack[index] === p.packageId
                          ? "#DCF896"
                          : "#BFEBFF",
                    }}
                    key={index}
                  >
                    <div className="package_card_body">
                      <div>
                        <h3>{p.packageName}</h3>
                        <p>{p.description}</p>
                      </div>
                      <div className="package_card_body">
                        <h2>₹ {p.price}</h2>
                        <button
                          className="card_button"
                          style={{
                            backgroundColor:
                              selectedPack[index] === p.packageId
                                ? ["#47C353"]
                                : ["#00b1ff"],
                            padding: 10,
                            color: "#ffffff",
                          }}
                          onClick={() =>
                            selectedPack[index] === p.packageId
                              ? onRemove(p, index)
                              : onAdd(p, index)
                          }
                          type="submit"
                        >
                          {selectedPack[index] === p.packageId
                            ? "Remove"
                            : "Add"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null
              )
            ) : (
              <> </>
            )}
          </div>

          <div>
            <div className="cart_card">
              <div className="cart_card_para">
                <h3>Total Price</h3>
                
                {discountAmount===0 ? (
                  <h2>₹ {totalPrice}</h2>
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        textDecorationLine: "line-through",
                        marginRight: 5,
                      }}
                    >
                      ₹ {totalPrice}
                    </div>
                    <h2>₹ {totalPrice - discountAmount}</h2>
                  </div>
                )}

              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  fullWidth
                  className="auth_textField"
                  id="name"
                  label="Enter Coupon Code"
                  variant="outlined"
                  onChange={(e) => setCheckCoupon(e.target.value)}
                />
                <SophiaButton
                  backgroundColor="#FF4937"
                  margin="10px"
                  text={"Apply Coupon"}
                  loading={loading}
                  onClick={checkCoupValidity}
                />
              </div>
              <SophiaButton
                backgroundColor="#FF4937"
                margin="10px"
                text={"Buy Now"}
                loading={loading}
                onClick={buyNow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopPage;
