import React, { useState } from "react";
import Logo from "../../../assets/images/mcq.png";
import SophiaButton from "../../../components/button";
import Question from "./question";

function Mcq(props) {
  const [mcq, setMcq] = useState(true);
  const startPage = () => {
    setMcq(false);
  };

  return (
    <div>
      {mcq ? (
        <div
          className="note_sub_page"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="" style={{ width: "40vw" }} />
          <div>
            <SophiaButton
               fontSize="15px"  
              text={"START THE TEST!"}
              onClick={() => startPage()}
            />
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <Question mcqQuiz={props.quiz} finish={()=>setMcq(true)} />
        </div>
      )}
    </div>
  );
}

export default Mcq;
