import { Popover, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import Logo from "../../assets/images/sophia.png";
import UserIcon from "../../assets/images/user-icon.svg";
import DownArrow from "../../assets/images/down-arrow.png"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearProfile, logout } from "../../redux/actions";

function Header() {
  const profile = useSelector((state) => state.profileReducer.profile);
  const [anchorEl, setAnchorEl] = useState(null);

  const menuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const menuClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    menuClose()
    dispatch(logout());
    dispatch(clearProfile());
    localStorage.removeItem('token');
    history.push('/auth/phone_authentication');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="header">
      <img src={Logo} alt="Logo" />
      <ul>
        <li className="header_menu">
          <img src={UserIcon} alt="User" />
          <div className="header_profile">
            <h3>{profile.name}
              <div onClick={menuClick}><img src={DownArrow} alt="down-arrow" /></div>
            </h3>
            <p>Grade {profile.grade}</p>
          </div>
        </li>
      </ul>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={menuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={logOut}>Logout</MenuItem>
      </Popover>
    </div>
  );
}

export default Header;
