import React from "react";
import { FaPlay } from "react-icons/fa";
import { MdLock } from "react-icons/md";
import { API_URL } from "../../api/constantApi";
import Logo from "../../assets/images/sophia.png";

function TopicCard(props) {
  const { topic, chapterInfo, onClick} = props;
  
  return (
    <div className="topic_card" onClick={onClick}>
      <img
        className="topic_card_img"
        src={topic.image_path ? API_URL + topic.image_path : Logo}
        alt="Subject-card"
      />
      <div className="topic_card_content">
        <h4>{topic.topicName}</h4>
        <p>{topic.discription}</p>
      </div>
      {/* <div className="topic_card-icon" onClick={togglePopup}>
       */}
      {chapterInfo.purchased || chapterInfo.price === "" ? (
        <FaPlay className="topic_card_icon" />
      ) : (
        <MdLock className="topic_card_icon" />
      )}
      
    </div>
  );
}

export default TopicCard;
// import * as React from 'react';
// import Typography from '@mui/material/Typography';
// import { FaPlay } from "react-icons/fa";
// import { MdLock } from "react-icons/md";

// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

// export default function PopoverPopupState() {
//   return (
//     <PopupState variant="popover" popupId="demo-popup-popover">
//       {(popupState) => (
//         <div>
//           <Button variant="contained" {...bindTrigger(popupState)}>
//             Open Popover
//           </Button>
//           <Popover
//             {...bindPopover(popupState)}
//             anchorOrigin={{
//               vertical: 'bottom',
//               horizontal: 'center',
//             }}
//             transformOrigin={{
//               vertical: 'top',
//               horizontal: 'center',
//             }}
//           >
//             <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
//           </Popover>
//         </div>
//       )}
//     </PopupState>
//   );
// }
