import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import SophiaButton from "../../../components/button";

function Question(props) {
  //console.log(props.mcqQuiz,"questions")
  const initialColors = {
    option1: "#FFFFFF",
    option2: "#FFFFFF",
    option3: "#FFFFFF",
    option4: "#FFFFFF",
  };

  const Questions = props.mcqQuiz;
  const [question, setQuestion] = useState(Questions[0]);
  const [questionCount, setQuestionCount] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(initialColors);
  const [answerValidation, setAnswerValidation] = useState(false);
  const [mcqError, setMcqError] = useState(false);
  const [answerSubmit, setAnswerSubmit] = useState(false);

  const onAnswerClick = (option) => {
    setMcqError(false);
    setAnswerValidation(true);
    if (!answerSubmit) {
      if (option === "Option 1") {
        const anwer = { ...initialColors, option1: "#CCEFFE" };
        setSelectedAnswer(anwer);
      }
      if (option === "Option 2") {
        const anwer = { ...initialColors, option2: "#CCEFFE" };
        setSelectedAnswer(anwer);
      }
      if (option === "Option 3") {
        const anwer = { ...initialColors, option3: "#CCEFFE" };
        setSelectedAnswer(anwer);
      }
      if (option === "Option 4") {
        const anwer = { ...initialColors, option4: "#CCEFFE" };
        setSelectedAnswer(anwer);
      }
    }
  };

  const ansSubmit = () => {
    if (!answerSubmit) {
      if (answerValidation) {
        setAnswerSubmit(true);
        if (question.correctOption === "Option 1") {
          const anwerSubmit = { ...selectedAnswer, option1: "#DCF896" };
          setSelectedAnswer(anwerSubmit);
        }
        if (question.correctOption === "Option 2") {
          const anwerSubmit = { ...selectedAnswer, option2: "#DCF896" };
          setSelectedAnswer(anwerSubmit);
        }
        if (question.correctOption === "Option 3") {
          const anwerSubmit = { ...selectedAnswer, option3: "#DCF896" };
          setSelectedAnswer(anwerSubmit);
        }
        if (question.correctOption === "Option 4") {
          const anwerSubmit = { ...selectedAnswer, option4: "#DCF896" };
          setSelectedAnswer(anwerSubmit);
        }
      } else {
        setMcqError(true);
      }
    } else {
      nextQuestion(questionCount + 1);
    }
  };

  const nextQuestion = (questionNo) => {
    setAnswerSubmit(false);
    setAnswerValidation(false);
    setSelectedAnswer(initialColors);
    setQuestionCount(questionNo);
    setQuestion(Questions[questionNo]);
  };

  const finish = () => {
    props.finish();
  };

  return (
    <div className="note_sub_page">
      <FormControl component="fieldset">
        <p style={{ textAlign: "center" }}>
          {questionCount + 1} of {Questions.length}
        </p>
        <FormLabel>
          {" "}
          {question.question}
        </FormLabel>
        <RadioGroup>
          <FormControlLabel
            value={question.option1}
            control={<StyledRadio />}
            style={{ backgroundColor: selectedAnswer.option1 }}
            onClick={() => onAnswerClick("Option 1")}
            label={question.option1}
          />
          <FormControlLabel
            value={question.option2}
            control={<StyledRadio />}
            style={{ backgroundColor: selectedAnswer.option2 }}
            onClick={() => onAnswerClick("Option 2")}
            label={question.option2}
          />
          <FormControlLabel
            value={question.option3}
            control={<StyledRadio />}
            style={{ backgroundColor: selectedAnswer.option3 }}
            onClick={() => onAnswerClick("Option 3")}
            label={question.option3}
          />
          <FormControlLabel
            value={question.option4}
            control={<StyledRadio />}
            style={{ backgroundColor: selectedAnswer.option4 }}
            onClick={() => onAnswerClick("Option 4")}
            label={question.option4}
          />
        </RadioGroup>
        {mcqError ? (
        <p
          style={
            { textAlign: "center", color: "#E71600" }}
        >
          Select an option
        </p>
      ) : null}

        <div style={{marginLeft: "80%" }}>
          <SophiaButton 
            text={
              answerSubmit
                ? Questions.length === questionCount + 1
                  ? "FINISH"
                  : "CONTINUE"
                : "SUBMIT"
            }
            onClick={answerSubmit
              && Questions.length === questionCount + 1 ? finish : ansSubmit}
          />
        </div>
      </FormControl>
    </div>
  );
}
export default Question;

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "green",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "green",
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
