import React, { useState, useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import SophiaButton from "../../components/button";
import apiServices from "../../api/apiServices";
import { useDispatch } from "react-redux";
import { login } from "../../redux/actions";

function PhoneAuth(props) {
  const phoneNumber = useRef();
  const otpNumber = useRef();

  const dispatch = useDispatch();
  const [authData, setAuthData] = useState({
    phoneNumber: "",
    otp: "",
  });
  const [signIn, setSignIn] = useState();
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState({
    phoneNumber: false,
    otp: false,
    loading: false,
    message: "",
  });
  const [sentOtp, setSentOtp] = useState(false);

  const authHandle = (e) => {
    setAuthError({
      phoneNumber: false,
    });
    const newAuthData = { ...authData };
    newAuthData[e.target.id] = e.target.value;
    setAuthData(newAuthData);
  };

  const authVerify = (e) => {
    e.preventDefault();
    if (!sentOtp) {
      if (authData.phoneNumber === "" || authData.phoneNumber.length !== 10) {
        authErrorMessage("phone");
      } else {
        setLoading(true);
        apiServices
          .VerifyPhoneNumber(authData.phoneNumber)
          .then((res) => {
            setLoading(false);
            setSentOtp(true);
            setSignIn(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    } else {
      if (authData.otp.length <= 4) {
        authErrorMessage("otp");
      } else {
        //console.log(signIn,"signIn.data.type")
        setLoading(true);
        let params = `${authData.otp}?phoneNumber=${authData.phoneNumber}`;
        apiServices
          .VerifyOTP(params)
          .then((res) => {
            setLoading(false);
            localStorage.setItem("token", res.data.data.token);
            if (signIn?.data?.type === "LOGIN") {
              props.history.push('/dashboard')
            }
            else if (signIn?.otp?.type === "REGISTER") {
              props.history.push("/auth/create_profile");
            }
            dispatch(login(res.data.data));
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  };

  const authErrorMessage = (value) => {
    if (value === "phone") {
      setAuthError({
        phoneNumber: true,
        message: "Please enter your 10 digits number",
      });
      phoneNumber.current.focus();
    } else if (value === "otp") {
      setAuthError({
        otp: true,
        message: "invalid!!",
      });
      otpNumber.current.focus();
    }
  };

  // useEffect(() => {
  //   phoneNumber.current.focus();
  // }, []);

  return (
    <div className="auth_page">
      <div className="auth_box">
        <div className="auth_title">
          Enter {!sentOtp ? "Phone Number" : "OTP"}
        </div>
        <p>Enter Your Mobile Phone Number to Receive OTP </p>

        {!sentOtp ? (
          <>
            <TextField
              fullWidth
              ref={phoneNumber}
              value={authData.phoneNumber}
              style={{ marginTop: "20px" }}
              id="phoneNumber"
              label="Phone Number"
              variant="outlined"
              onChange={(e) => authHandle(e)}
            />
            {authError.phoneNumber ? (
              <div className="error_message">{authError.message}</div>
            ) : null}
          </>
        ) : (
          <>
            <TextField
              fullWidth
              ref={otpNumber}
              value={authData.otp}
              style={{ marginTop: "20px" }}
              id="otp"
              label="Otp"
              variant="outlined"
              onChange={(e) => authHandle(e)}
            />
            {authError.otp ? (
              <div className="error_message">{authError.message}</div>
            ) : null}
          </>
        )}

        <SophiaButton
          onClick={(e) => authVerify(e)}
          text={"Verify"}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default PhoneAuth;
