import React from 'react'

function MainTitle(props) {
    return (
        <div className= "main_title">
            <h3>{props.title}</h3>
            <p>{props.subTitle}</p>
            <p>{props.para}</p>
        </div>
    )
}

export default MainTitle
