import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import SophiaButton from "../../components/button";
import { useDispatch, useSelector } from "react-redux";
import apiServices from "../../api/apiServices";
import { useHistory } from "react-router";
import { clearProfile, logout } from "../../redux/actions";

function CreateProfile(props) {
  const user = useSelector((state) => state.userReducer.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [board, setBoard] = useState();
  const [grade, setGrade] = useState();
  const [stream, setStream] = useState();
  const [formData, setFormData] = useState({
    name: "",
    board: "",
    grade: "",
    stream: "",
    gradeId: "",
    streamId: "",
    boardId: "",
    reference:""
  });

  const getBoardData = () => {
    apiServices
      .GetBoards()
      .then((res) => {
        setBoard(res.data.data);
      })
      .catch((err) => {});
  };

  const getGradeData = (selectedBoard) => {
    setFormData({
      ...formData,
      boardId: selectedBoard._id,
      board: selectedBoard.board,
    });
    apiServices
      .GetGrades(selectedBoard._id)
      .then((res) => {
        setGrade(res.data.data);
      })
      .catch((err) => {});
  };

  const getStreamData = (selectedGrade) => {
    setFormData({
      ...formData,
      gradeId: selectedGrade._id,
      grade: selectedGrade.grade,
    });
    apiServices
      .GetStreams(selectedGrade._id)
      .then((res) => {
        setStream(res.data.data);
      })
      .catch((err) => {});
  };

  const selectedStreamData = (selectedStream) => {
    setFormData({
      ...formData,
      streamId: selectedStream._id,
      stream: selectedStream.streamName,
    });
  };

  const nameChange = (e) => {
    if (e.target.value.length > 2) {
      setError(false);
    }
    const newAuthData = { ...formData };
    newAuthData[e.target.id] = e.target.value;
    setFormData(newAuthData);
  };

  useEffect(() => {
    getBoardData();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    if (formData.name.length < 3) {
      setError(true);
    } else {
      //console.log(formData, "user");
      let userId = user.id ? user.id : user._id;
      setLoading(true);
      apiServices
        .CreateProfile(userId, formData, user.token)
        .then((res) => {
          setLoading(false);
          props.history.push("/dashboard/");
        })
        .catch((err) => {
          setLoading(false);
          logOut();
        });
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    dispatch(clearProfile());
    localStorage.removeItem("token");
    history.push("/auth/phone_authentication");
  };

  return (
    <div className="auth_page">
      <div className="auth_box">
        <div className="auth_title">Create Profile</div>
        <TextField
          fullWidth
          className="auth_textField"
          id="name"
          label="Enter Full Name"
          variant="outlined"
          onChange={(e) => nameChange(e)}
        />
        {error ? <div className="error_message">Enter your Name</div> : null}
        {board?.length > 0 ? (
          <>
            <div className="auth_subtitle">Select School Board</div>
            <div className="board_chip">
              {board.map((item, index) => (
                <Chip
                  style={{
                    marginRight: 10,
                    backgroundColor:
                      formData.boardId === item._id ? "#C3EA5F" : "#D9D9E1",
                  }}
                  label={item.board}
                  key={index}
                  onClick={() => getGradeData(item)}
                />
              ))}
            </div>
          </>
        ) : null}

        {grade?.length > 0 ? (
          <>
            <div className="auth_subtitle">Select Grade</div>
            <div className="board_chip">
              {grade.map((item, index) => (
                <Chip
                  style={{
                    marginRight: 10,
                    backgroundColor:
                      formData.gradeId === item._id ? "#C3EA5F" : "#D9D9E1",
                  }}
                  label={item.grade}
                  key={index}
                  onClick={() => getStreamData(item)}
                />
              ))}
            </div>
          </>
        ) : null}

        {stream?.length > 0 ? (
          <>
            <div className="auth_subtitle">Select Stream</div>
            <div className="board_chip">
              {stream.map((item, index) => (
                <Chip
                  style={{
                    marginRight: 10,
                    backgroundColor:
                      formData.streamId === item._id ? "#C3EA5F" : "#D9D9E1",
                  }}
                  label={item.streamName}
                  key={index}
                  onClick={() => selectedStreamData(item)}
                />
              ))}
              <TextField
                fullWidth
                style={{marginTop:20}}
                className="auth_textField"
                id="reference"
                label="Reference"
                variant="outlined"
                onChange={(e) => nameChange(e)}
              />
            </div>
          </>
        ) : null}
        {formData.stream ? (
          <SophiaButton
            onClick={(e) => submit(e)}
            text={"CONTINUE"}
            loading={loading}
          />
        ) : null}
      </div>
    </div>
  );
}
export default CreateProfile;
