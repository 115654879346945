import { CircularProgress } from '@material-ui/core'
import React from 'react'

function Loader() {
    return (
        <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    )
}

export default Loader
