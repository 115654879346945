const profileInfoState = {};

const profileReducer = (state = profileInfoState, action) => {
    switch (action.type) {
        case "CURRENT_PROFILE":
            return { ...state, profile: action.payload }
        case "CLEAR_PROFILE":
            return {};
    }

    return state;
};

export default profileReducer;