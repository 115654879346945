const userInfoState = {};

const userReducer = (state = userInfoState, action) => {
    switch (action.type) {
        case "LOGIN":
            return { ...state, user: action.payload }
        case "LOGOUT":
            return {};
    }

    return state;
};

export default userReducer;