import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Sophia from "../../assets/images/sophia.png";

import SophiaButton from "../button";

function ShopCard(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  const history = useHistory();
  const shopCart = () => {
    history.push(`/dashboard/shop`);
  };
 
  return (
    <div className="shop_card">
      <Dialog onClose={handleClose} open={open} className="shop_card_body">
        <DialogContent>
          <img src={Sophia} alt="Sophia" height="60px" width="60px" />

          <p style={{ color: "gray" }}>
            Please unlock now to access this content
          </p>
          <SophiaButton
            text="UNLOCK"
            backgroundColor="#C3EA5F"
            padding="10px 15px"
            borderRadius="10px"
            fontSize="17px"
            width="auto"
            color="#000"
            margin="5px 10px"
            onClick={()=>shopCart()} />
          <SophiaButton
            text="SORRY"
            backgroundColor="#FF4937"
            padding="10px 15px"
            borderRadius="10px"
            fontSize="17px"
            margin="5px 10px"
            width="auto"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ShopCard;
