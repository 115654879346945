import React from "react";

function Vimeo(props) {
  return (
    <div className="container-iframe">
      <iframe
        title="vimeo"
        src={`https://player.vimeo.com/video/${props.videoLink}`}
        className="responsive-iframe"
        frameBorder="0"
        margin="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default Vimeo;
