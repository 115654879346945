export const login = (user) => {
    return { type: "LOGIN", payload: user };
};

export const logout = () => {
    return { type: "LOGOUT" };
};

export const profile = (info) => {
    return { type: "CURRENT_PROFILE", payload: info };
};

export const clearProfile = () => {
    return { type: "CLEAR_PROFILE" };
};
