import React from "react";
import { Switch, Route, Redirect,withRouter } from "react-router-dom";
import CreateProfile from "./createProfile";
import PhoneAuth from "./phoneAuth";


function AuthRoute() {
  return (
    <div className="authentication">
      <Switch>
      <Route exact path='/auth'>
          <Redirect to={"/auth/phone_authentication"} />
        </Route>
        <Route exact path="/auth/phone_authentication" component={PhoneAuth} />
        <Route exact path="/auth/create_profile" component={CreateProfile} />
      </Switch>
    </div>
  );
}

export default withRouter(AuthRoute);
