import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MainTitle from "../../../components/mainTitle";
import Vimeo from "../../../components/video/vimeo";
import Notes from "./notes";
import Mcq from "./mcq";
import apiServices from "../../../api/apiServices";
import Loader from "../../../components/loader";

function TopicPage(props) {
  let topicId = props.match.params.id;
  let topicTitle = props.match.params.name;
  const [value, setValue] = useState(0);
  let Param = topicId;

  const [topicData, setTopicData] = useState();
  const [topicDataLoading, setTopicDataLoading] = useState(false);
  const [topicErr, setTopicErr] = useState(false);

  const [quizData, setQuizData] = useState();
  const [quizDataLoading, setQuizDataLoading] = useState(false);
  const [quizErr, setQuizErr] = useState(false);

  const TopicVideo = () => {
    setTopicDataLoading(true);
    apiServices
      .GetTopicVideo(Param)
      .then((res) => {
        setTopicData(res.data.data[0]);
        setTopicDataLoading(false);
      })
      .catch((error) => {
        setTopicDataLoading(false);
        setTopicErr(true);
      });
  };

  const GetQuiz = () => {
    setQuizDataLoading(true);
    apiServices
      .GetQuizUrl(Param)
      .then((res) => {
        setQuizData(res.data.data);
        // //console.log(res.data.data, "quiz")
        setQuizDataLoading(false);
      })
      .catch((error) => {
        setQuizDataLoading(false);
        setQuizErr(true);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    TopicVideo();
    GetQuiz();
  }, []);

  return (
    <div className="topic_page" >
      <MainTitle title={topicTitle} />
      {topicData ? (
        <div className="topic_page_body">
          <Vimeo videoLink={topicData.videoLink.split("/")[3]} />

          <div className="note_page">
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
            >
              <AntTab label="NOTES" />

              <AntTab label="MCQ" />
            </AntTabs>
            <div>
              {value === 0 ? (
                <Notes notes={topicData.description} />
              ) : (
                <Mcq 
                quiz={quizData.questions}
                 />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
export default TopicPage;

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#28B0DB",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 80,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(10),

    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "black",

      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: "black",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);























