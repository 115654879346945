import React from "react";
import { API_URL } from "../../api/constantApi";
import SophiaButton from "../button";

function SubjectCard(props) {
  const {bg,title,caption,icon,onClick} = props
  return (
   
    <div style={{ backgroundColor:bg }} className="subject_card"  onClick={onClick}>
      <div className="subject_card_c1"></div>
      <h3>{title}</h3>
      <div className="subject_card_body">
        <img src={API_URL+icon} alt="Subject-card" />
        <div style={{ width: "100%" }}>
          <p>{caption}</p>
          <SophiaButton
            text={"START"}
            padding="10px"
            margin="5px"
            fontSize="15px"
            width="auto"
            onClick={onClick}
          />
        </div>
      </div>
      <div className="subject_card_c2"></div>
    </div>
  
  );
}

export default SubjectCard;
