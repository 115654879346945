import React, { useState, useEffect } from "react";
import apiServices from "../../../api/apiServices";
import MainTitle from "../../../components/mainTitle";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { profile, logout } from "../../../redux/actions";
import Subject from "./subject";

function LandingPage(props) {
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  // let storedData = tokenInfo;

  ////console.log(tokenInfo);
  const [userData, setUserData] = useState("");
  const [userInfo, setInfo] = useState("");
  const [loading, setLoading] = useState(true);

  const GetUserData = () => {
    //console.log(userInfo.id);
    setTimeout(() => {
      GetDate(user);
    }, 1000);
  };

  const GetDate = (value) => {
    // //console.log(user)
    let userId;
    let token = localStorage.getItem("token");
    if (token) {
      const payloadStart = token.indexOf(".") + 1;
      const payloadEnd = token.lastIndexOf(".");
      let payload = token.substring(payloadStart, payloadEnd);

      if (payload.length === 0) {
      } else {
        payload = atob(payload);
        const jsonPayload = JSON.parse(payload);
        //console.log(jsonPayload);
        userId = jsonPayload.id;
      }
      apiServices
        .GetUserDetails(userId, token)
        .then((res) => {
          if (res.data.data.profiles[0]) {
            setUserData(res.data.data);
            dispatch(profile(res.data.data));
          } else {
            // dispatch(logout());
            // localStorage.removeItem("token");
            props.history.push("/auth/create_profile");
            // props.navigation.navigate("CreateProfile", {
            //   token: storedData.token,
            //   userId: storedData.userId,
            // });
          }
          setLoading(false);
        })
        .catch((error) => {
          dispatch(logout());
          localStorage.removeItem("token");
          props.history.push("/auth/phone_authentication");
          setLoading(false);
        });
    } else {
      props.history.push("/auth/phone_authentication");
    }
  };

  // to={`/addstockingpoint/${item._id}`}
  useEffect(() => {
    setTimeout(() => {
      GetUserData();
    }, 1000);
  }, []);
  return (
    <div>
      {userData ? (
        <div>
          <MainTitle
            title={`Hello ${userData.name}`}
            subTitle="Welcome to Sophia Academy"
          />
          <div className="dispaly-flex">
            <Subject userData={userData} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="dispaly-flex landing_page_height"
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default LandingPage;
