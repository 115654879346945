import axios from "axios";
import { API_URL } from "./constantApi";

class ApiServices {
  async VerifyPhoneNumber(Params) {
    // //console.log(Params, "Params");
    const response = await axios.get(
      `${API_URL}user/verifyPhoneNumber/${Params}`
    );
    //console.log(response, "VerifyPhoneNumber");
    return response;
  }
  
  async VerifyOTP(Params) {
    const response = await axios.get(`${API_URL}user/verifyOTP/${Params}`);
    return response;
  }

  async GetBoards() {
    const response = await axios.get(`${API_URL}admin/getBoards`);
    return response;
  }

  async GetGrades(Params) {
    ////console.log(Params,"GetGrades")
    const response = await axios.get(`${API_URL}admin/getGrades/${Params}`);
    return response;
  }

  async GetStreams(Params) {
    ////console.log(Params,"GetStreams")
    const response = await axios.get(`${API_URL}admin/getStreams/${Params}`);
    return response;
  }

  async CreateProfile(Params, Body, token) {
    const response = await axios.post(
      `${API_URL}user/createProfile/${Params}`,
      Body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  }

  async GetUserDetails(Params, token) {
    //console.log(`${API_URL}user/getUser/${Params}`);
    const response = await axios.get(`${API_URL}user/getUser/${Params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  }

  async GetSubjectsUrl(Params) {
    const response = await axios.get(`${API_URL}admin/getSubjects/${Params}`);
    return response;
  }

  async GetChaptersUrl(Params) {
    const response = await axios.get(
      `${API_URL}admin/getPackagesbySubject/${Params}`
    );
    return response;
  }

  async GetTopicVideo(Params) {
    //console.log(Params, "TopicVideo");
    const response = await axios.get(`${API_URL}admin/getVideos/${Params}`);
    return response;
  }

  async GetQuizUrl(Params) {
    //console.log(Params, "GetQuizUrl");
    const response = await axios.get(`${API_URL}admin/getMCQs/${Params}`);
    return response;
  }
  
  async GetPackages(Params) {
    // //console.log(Params,"GetPackages");
    const response = await axios.get(`${API_URL}admin/getPackages/${Params}`);
    return response;
  }

  async GetCreateOrder(Body, Params, token) {
    const response = await axios.post(
      `${API_URL}user/createOrder/${Params}`,
      Body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  }
  async CheckCoupValidity(Params) {
    const response = await axios.post(
      `${API_URL}admin/checkCouponValidity?${Params}`,
    );
    return response;
  }

  async GetPaymentStatus(Params, token) {
    ////console.log(Params,"GetPaymentStatus");
    const response = await axios.get(
      `${API_URL}user/getPaymentStatus/${Params}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  }

  async GetPayment(Body) {
    ////console.log(Body,"createOrder");
    const response = await axios.post(`https://secure.payu.in/_payment`, Body);
    return response;
  }
}

export default new ApiServices();
