import React from "react";

function Notes(props) {
  return (
    <div>
      <div className="note_sub_page">
        
        <div dangerouslySetInnerHTML={{ __html: props.notes }} />
      </div>
    </div>
  );
}

export default Notes;
