import { Container } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import ShopCard from "../../components/card/shopCard";
import Footer from "../../components/menu/footer";
import Header from "../../components/menu/header";
import ChapterPage from "./chapterPage";
import LandingPage from "./landingPage";
import ShopPage from "./shopPage";
import TopicPage from "./topicPage";
import Question from "./topicPage/question";

function PublicStudentRoute() {
  const profile = useSelector((state) => state.profileReducer.profile);
  return (
    <div>
      {profile?<Header />:null}
      <div className="main_bg">
        <Container maxWidth="lg">
          <Switch>
            <Route exact path="/dashboard">
              <Redirect to={"/dashboard/subject"} />
            </Route>

            <Route exact path="/dashboard/subject" component={LandingPage} />
            <Route exact path="/dashboard/chapter/:id/:sub" component={ChapterPage} />
            <Route exact path="/dashboard/topic/:id/:name" component={TopicPage} />
            <Route exact path="/dashboard/shop" component={ShopPage} />
            <Route exact path="/dashboard/question" component={Question} />
            <Route exact path="/dashboard/shop_card" component={ShopCard} />
          </Switch>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default PublicStudentRoute;