import React from "react";
import { CircularProgress } from "@material-ui/core";

function SophiaButton(props) {
  const { width, padding, backgroundColor, fontSize, onClick, text, borderRadius, loading, color,margin } = props;
  return (
    <button
      className="button"
      style={{
        width: width ? width : "100%",
        padding: padding ? padding : 15,
        backgroundColor: backgroundColor ? backgroundColor : "#0090D0",
        fontSize: fontSize ? fontSize : "20px",
        color: color ? color : "#ffffff",
        borderRadius: borderRadius ? borderRadius : "5px",
        margin: margin ? margin : "20px 0px",
      }}
      onClick={onClick}
      type="submit"
    >
      {loading ? (
        <CircularProgress size={15} style={{ color: "black" }} />
      ) : (
        text
      )}
    </button>
  );
}

export default SophiaButton;
