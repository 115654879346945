import React from "react";

function Footer() {
    let currentYear = new Date().getFullYear();
  return (      
    <div className="footer">
      <p className="footer_text">
        &copy; Copyright {currentYear} &nbsp;
     Sophia App</p>
    </div>
  );
}

export default Footer;
